import {gsap} from '~/assets/js/gsap/gsap';
import ScrollToPlugin from '~/assets/js/gsap/ScrollToPlugin';
import CustomEase from '~/assets/js/gsap/CustomEase';
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);

/*
 * Easings
 */

CustomEase.create('in', '0.77, 0, 0.68, 0.79');
CustomEase.create('out', '0.32, 0.21, 0.175, 1');
CustomEase.create('inOut', '0.77, 0, 0.175, 1');

/*
 * Speeds
 */

global.T_FAST = 0.3;
global.T_MEDIUM = 0.6;
global.T_SLOW = 0.9;

export default ({app, store}, inject) => {
  inject('gsap', gsap);
};
